import buildQRBox from './shared/buildQRBox';
import { TemplateFunction } from './types';

const defaultTemplate: TemplateFunction = ({ qrCode = '' }) => {
  const QRBox = buildQRBox({
    qrCode,
    left: 0,
    top: 0,
  });

  return `
    <svg viewBox="0 0 210 210" width="210" height="210" fill="none" xmlns="http://www.w3.org/2000/svg">
      ${QRBox}
    </svg>
  `;
};

export default defaultTemplate;
