import buildQRBox from './shared/buildQRBox';
import buildSVGText from './shared/buildSVGText';
import { TemplateFunction } from './types';

const first: TemplateFunction = ({
  text = '',
  qrCode = '',
  color = 'black',
  background = 'none',
  qrContainerBackground = 'none',
  textColor,
}) => {
  const QRBox = buildQRBox({
    qrCode,
  });

  const SVGText = buildSVGText({
    value: text,
    color: textColor,
  });

  return `
    <svg width="340" height="454" viewBox="0 0 340 454" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect y="0" x="0" width="340" height="454" />
      <rect x="3" y="3" width="334" height="448" rx="19" stroke="${color}" fill="${background}" stroke-width="6"/>
      <rect x="45" y="45" width="250" height="250" rx="20" fill="${qrContainerBackground}"/>
      ${QRBox}
      <path d="M1 355H339V434C339 444.493 330.493 453 320 453H20C9.50659 453 1 444.493 1 434V355Z" fill="${color}"/>
      <path d="M1 355H339V434C339 444.493 330.493 453 320 453H20C9.50659 453 1 444.493 1 434V355Z" stroke="${color}" stroke-width="2"/>
      ${SVGText}
    </svg>
  `;
};

export default first;
